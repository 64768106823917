<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('sub_payment_edit_intro')"></h4>
  </div>
  <div class="col-12 mb-5" [innerHTML]="translationService.getTranslation('sub_payment_edit_description')">
  </div>
</div>

<ng-container *ngIf="!isLoadingSub">
  <div class="mb-5">
    <div class="row">
      <div class="col-12">
        {{sub.productDescription}} / {{sub.variantDescription}}
      </div>
    </div>
    <div class="row" *ngIf="sub.backendId > 0">
      <div class="col-12" [innerHTML]="translationService.getTranslation('order_id') + ': ' + sub.backendId">
      </div>
    </div>
    <div class="row" *ngIf="sub.curBilling">
      <div class="col-12" [innerHTML]="translationService.getTranslation('customer') +
        ': ' + sub.curBilling.person.salutationDescription + ' ' +
        (sub.curBilling.person.titleDescription != '' ? sub.curBilling.person.titleDescription + ' ' : '') +
        sub.curBilling.person.firstname + ' ' +
        sub.curBilling.person.lastname">
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="isLoadingSub" style="text-align: center;">
  <app-loading-spinner></app-loading-spinner>
</div>

<app-general-errors [errors]="errorsArr"></app-general-errors>

<form [formGroup]="paymentEditForm">
  <div class="row">


    <mat-form-field class="col-12" (click)="picker.open()" formGroupName="validDate">
      <mat-label [innerHTML]="translationService.getTranslation('startdate')"></mat-label>
      <input matInput [min]="minDate" [matDatepicker]="picker" readonly required formControlName="validFrom">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker [touchUi]="isMobile" #picker></mat-datepicker>
      <mat-error
        *ngIf="paymentEditForm.get('validDate').hasError('message')">{{paymentEditForm.get('validDate').getError('message')}}</mat-error>
    </mat-form-field>

    <mat-form-field class="col-12" formGroupName="paymentType">
      <mat-label [innerHTML]="translationService.getTranslation('payment_type')"></mat-label>
      <mat-select formControlName="paymentMethod" required [compareWith]="compareFormOfPayment" [value]="paymentEditForm.get('paymentType.paymentMethod').value">
        <ng-container *ngFor="let formOfPayment of filteredFormsOfPayment">
          <mat-option [value]="formOfPayment">{{formOfPayment.description}}</mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="paymentEditForm.get('paymentType.paymentMethod').hasError('message')">
        {{paymentEditForm.get('paymentType.paymentMethod').getError('message')}}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-12" formGroupName="paymentType">
      <mat-label [innerHTML]="translationService.getTranslation('payment_rhythm')"></mat-label>
      <mat-select formControlName="paymentFrequency" required [compareWith]="compareBaseData" [value]="paymentEditForm.get('paymentType.paymentFrequency').value">
        <ng-container *ngFor="let paymentFrequency of filteredPaymentFrequencies">
          <mat-option [value]="paymentFrequency">{{paymentFrequency.description}}</mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="paymentEditForm.get('paymentType.paymentFrequency').hasError('message')">
        {{paymentEditForm.get('paymentType.paymentFrequency').getError('message')}}
      </mat-error>
    </mat-form-field>

    <ng-container formGroupName="bank" *ngIf="needIbanBic">
      <mat-form-field class="col-12">
        <mat-label [innerHTML]="translationService.getTranslation('payment_iban')"></mat-label>
        <input maxLength="34" matInput formControlName="swiftIBAN" required #iban>
        <app-signs-counter *nfIf="iban.value.length > 0 && iban.value.length <= iban.maxLength" [actual]="iban.value.length" [possible]="iban.maxLength"></app-signs-counter>
        <mat-error *ngIf="paymentEditForm.get('bank.swiftIBAN').hasError('message')">
          {{paymentEditForm.get('bank.swiftIBAN').getError('message')}}
        </mat-error>
      </mat-form-field>
      <div *ngIf="paymentEditForm.get('bank.swiftIBAN').status === 'PENDING'">
        <app-loading-spinner [type]="'small'"></app-loading-spinner>
      </div>

      <mat-form-field class="col-12">
        <mat-label [innerHTML]="translationService.getTranslation('payment_bic')"></mat-label>
        <input matInput formControlName="swiftBIC" required>
        <mat-error *ngIf="paymentEditForm.get('bank.swiftBIC').hasError('message')">
          {{paymentEditForm.get('bank.swiftBIC').getError('message')}}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="needInvoiceMailAddress">
      <mat-form-field class="col-12">
        <mat-label [innerHTML]="translationService.getTranslation('payment_invoice_email')"></mat-label>
        <input matInput formControlName="invoiceEmail" required>
        <mat-error *ngIf="paymentEditForm.get('invoiceEmail').hasError('message')">
          {{paymentEditForm.get('invoiceEmail').getError('message')}}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <div class="col-12">
      <button mat-raised-button [hidden]="isSaving"
              color="primary" (click)="onSendPayment()" [innerHTML]="translationService.getTranslation('button_send')"></button>
    </div>
  </div>
</form>
